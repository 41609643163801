<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': $route.query.ismobileapp == undefined,
    }"
  >
    <div class="row">
      <div class="col-sm-12">
        <div class="content-wrapper">
          <h1 class="text-center title">
            Security Policy
          </h1>
          <div class="content-box">
            <div class="lead">
              <h3>USERS REFUND POLICY TERMS</h3>
            </div>
            <div class="desp">
              <p>
                These terms and conditions govern the Trepr’s User Refund Policy (the User Refund
                Policy) available to Seekers who request travel services listed by a Traveler
                through the Trepr platform (the "Site"), suffer a Service Issue and the obligations
                of the Traveler associated with the User Refund Policy.
              </p>
              <br>
              <ol>
                <li>
                  <p>
                    Service Issue. A Service Issue means any one of the following but accepted to
                    certain degree (which is up to Trepr’s discretion):
                  </p>

                  <ol class="list-alpha">
                    <li>
                      the Traveler of the travel Service

                      <ul class="list-square">
                        <li>
                          cancels a request shortly before the scheduled start of the service, or
                        </li>

                        <li>
                          fails to provide the Seeker with the reasonable travel service (e.g., by
                          providing the status or by communicating delay if any).
                        </li>
                      </ul>
                      <br>
                    </li>

                    <li>
                      the description of the travel service in the listing on the Site is materially
                      inaccurate with respect to:

                      <ul class="list-square">
                        <li>
                          the extent of travel service (e.g., incorrect source &amp; destination,
                          misleading package or project details, incorrect location specified for
                          project etc.),
                        </li>

                        <li>
                          special conditions or features represented in the listing description are
                          not provided as agreed upon
                        </li>

                        <li>
                          Damaged, stolen or undelivered items reported in any of travel services.
                        </li>
                      </ul>
                      <br>
                    </li>

                    <li>
                      at the start of the Seeker’s service, the travel service:

                      <ul class="list-square">
                        <li>User doesn’t report on time</li>

                        <li>
                          Traveler delegates another person to serve without prior approval or
                          notice
                        </li>

                        <li>Information provided by Member is mismatching.</li>
                      </ul>
                    </li>
                  </ol>
                  <br>
                </li>

                <li>
                  <p>
                    The User Refund Policy. If you are a Seeker and suffer a Service Issue, we
                    agree, at our discretion, to either
                  </p>

                  <ul class="list-alpha">
                    <li>
                      <p>
                        reimburse you up to the amount paid by you through the Site, as determined
                        by Trepr in our discretion, depending on the nature of the Service Issue
                        suffered or
                      </p>
                    </li>

                    <li>
                      <p>
                        use our reasonable efforts to seek alternative traveler and serve your
                        travel service request which in our determination is reasonably comparable
                        to the travel services described in your original request in terms of number
                        of services, nature of the service, and cost involved. All determinations of
                        Trepr with respect to the User Refund Policy, including without limitation
                        the size of any refund, shall be final and binding on the Seekers and
                        Travelers.
                      </p>
                    </li>
                  </ul>
                  <br>
                </li>

                <li>
                  <p>
                    Conditions to Claim a Service Issue. Only a Seeker may submit a claim for a
                    service Issue. If you are a Seeker, in order to submit a valid claim for a
                    service Issue and receive the benefits with respect to your request, you are
                    required to meet each of the following conditions:
                  </p>

                  <ul class="list-alpha">
                    <li>
                      <p>
                        you must bring the Service Issue to our attention in writing
                        (Trepr.com/contact) or via telephone and provide us with information
                        (including photographs or other evidence) about the travel service and the
                        circumstances of the Service Issue within 24 hours after the completion of
                        your travel service, and must respond to any requests by us for additional
                        information or cooperation on the Service Issue.
                      </p>
                    </li>

                    <li>
                      <p>
                        you must not have directly or indirectly caused the Service Issue (through
                        your action, omission or negligence).
                      </p>
                    </li>

                    <li>
                      <p>
                        you must have used reasonable efforts to try to remedy the circumstances of
                        the Service Issue with the Traveler prior to making a claim for a Service
                        Issue.
                      </p>
                    </li>
                  </ul>
                  <br>
                </li>

                <li>
                  <p>
                    Minimum Quality Standards, Traveler Responsibilities and Reimbursement to
                    Seeker. If you are a Traveler, you are responsible for ensuring that the travel
                    services you list on the Site meet basic expectations and minimum quality
                    standards regarding communication, status notification, courtesy call or action
                    and do not present a seeker with Service Issues. During the 24-hour period
                    following the Seeker’s service, Travelers should be available, or make a
                    third-party available, in order to try, in good faith, to resolve Seeker issues.
                  </p>

                  <p>If you are a Traveler, and if</p>

                  <ul class="list-alpha">
                    <li>
                      <p>
                        Trepr determines that a Seeker has suffered a Service Issue related to a
                        travel service listed by you and
                      </p>
                    </li>

                    <li>
                      <p>
                        Trepr either reimburses that Seeker any amount up to the amount paid or no
                        amount quoted by the Seeker through the Site for the travel service or
                        provides an alternative travel service to the Seeker, you agree to reimburse
                        Trepr up to the amount paid by Trepr within 10 days of Trepr’s request. All
                        determinations of Trepr with respect to the User Refund Policy, including
                        without limitation the size of any refund to the Seeker, shall be final and
                        binding on the Seekers and Travelers. You also agree that in order for you
                        to reimburse Trepr up to the amount paid/negotiated by Trepr, Trepr may
                        off-set or reduce any amounts owed by Trepr to you by this amount. Trepr
                        also has the authority to charge traveler’s payment method, even without
                        your consent, for an amount that you owe to Seeker for any loss incurred due
                        to Service Issue. If the Seeker remains for part or all of the travel
                        service despite the Service Issue, the Seeker will receive a refund that
                        will reduce the amount of the Service Fees ultimately paid to you. If the
                        Seeker has found an alternative travel service, you may lose part or all of
                        the travel service Fee payment for the request and you may be responsible
                        for reasonable additional costs incurred for the inconvenience caused or
                        charges that caused Seeker to find alternative travel service.
                      </p>
                    </li>
                  </ul>
                  <br>
                  <p>
                    The rights of the Seekers under the User Refund Policy supersede the
                    cancellation policy established by a Traveler. If you dispute the Service Issue
                    you may notify us in writing (Trepr.com/contact) or via telephone and provide us
                    with information (including photographs or other evidence) disputing the claims
                    regarding the Service Issue, provided you must have used reasonable and good
                    faith efforts to try to remedy the Service Issue with the Seeker prior to
                    disputing the Service Issue claim. You agree that all determinations of Trepr
                    with respect to the Service Issue shall be final and binding on the Seekers and
                    Travelers regardless of your submission of a dispute against such Service Issue.
                    In the event of one or more Service Issues, Trepr, in its discretion, may elect
                    to take additional actions. These actions include, but are not limited to,
                    negatively affecting your listing ranking, automated reviews indicating Service
                    Issues, cancelling future bookings, suspending or removing the listing of the
                    travel service or imposing penalties or fees for the administrative burden
                    associated with the Service Issues.
                  </p>
                  <br>
                </li>

                <li>
                  <p>General Provisions.</p>

                  <ol class="list-alpha">
                    <li>
                      <p>
                        No Assignment/No Insurance. This User Refund Policy is not intended to
                        constitute an offer to insure, does not constitute insurance or an insurance
                        contract, does not take the place of insurance obtained or obtainable by the
                        Seeker, and the Seeker has not paid any premium in respect of the User
                        Refund Policy. The benefits provided under this User Refund Policy are not
                        assignable or transferable by you.
                      </p>
                    </li>

                    <li>
                      <p>
                        Modification or Termination. Trepr reserves the right to modify or terminate
                        this User Refund Policy, at any time, in its sole discretion, and without
                        prior notice. If Trepr modifies this User Refund Policy, we will post the
                        modification on the Site or provide you with notice of the modification and
                        Trepr will continue to process all claims for Service Issues made prior to
                        the effective date of the modification.
                      </p>
                    </li>

                    <li>
                      <p>
                        Entire Agreement and Definitions. This User Refund Policy constitutes the
                        entire and exclusive understanding and agreement between Trepr and you
                        regarding the User Refund Policy and supersedes and replaces any and all
                        prior oral or written understandings or agreements between Trepr and you
                        regarding the User Refund Policy. Capitalized terms not otherwise defined
                        herein shall have the meaning set forth in the Trepr Terms of Service.
                      </p>
                    </li>

                    <li>
                      <p>
                        Controlling Law. This User Refund Policy will be interpreted in accordance
                        with the laws of the United Kingdom, without regard to its conflict-of-law
                        provisions.
                      </p>
                    </li>

                    <li>
                      <p>
                        Limitation of Liability. IN NO EVENT WILL TREPR’S AGGREGATE LIABILITY
                        ARISING OUT OF OR IN CONNECTION WITH THIS TREPR POLICY TERMS, EXCEED THE
                        AMOUNT OF THE TRAVEL SERVICE FEES / QUOTE FOR PEOPLE, PACKAGE AND PROJECT
                        SERVICES COLLECTED BY TREPR FROM THE SEEKER. SOME JURISDICTIONS DO NOT ALLOW
                        THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
                        DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
                      </p>

                      <p>
                        YOU ACKNOWLEDGE AND AGREE THAT, BY POSTING A LISTING OR REQUESTING A travel
                        SERVICE OR OTHERWISE USING THE SITE, SERVICES AS A TRAVELER OR A SEEKER, YOU
                        ARE INDICATING THAT YOU HAVE READ, AND THAT YOU UNDERSTAND AND AGREE TO BE
                        BOUND BY THESE POLICY TERMS.
                      </p>
                    </li>
                  </ol>
                  <br>
                </li>

                <li>
                  <p>
                    Contacting Trepr. If you have any questions about the User Refund Policy, please
                    contact Trepr at
                    <a href="https://trepr.com/contact-us">https://trepr.com/contact-us</a>.
                  </p>
                  <br>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecurityPolicy',
  created() {
    document.title = 'Trepr - Security Policy | Flight Companion & Parcel Delivery Services';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'If you have any questions about the User Refund Policy, please contact Trepr.'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr, Trepr Security Policy,Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
  },
};
</script>

<style scoped></style>
